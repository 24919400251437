* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #f5ebe0;
  max-width: 100vw;
  overflow-x: hidden;
}

.App {
  text-align: center;
  padding: 3rem;
}

section {
  max-width: 1024px;
  padding: 2rem 2rem;
  margin: 3rem auto;
  box-shadow: 2px 2px 5px #7b6152;
  border-radius: 16px;
}

button {
  border: none;
  padding: 0.5rem 1rem;
  background-color: #d5bdaf;
  margin: 0.5rem;
  border-radius: 16px;
  appearance: none;
  color: #000 !important;
  box-shadow: 2px 2px 5px #d6ccc2;
}

button:hover {
  background-color: #e3d5ca;
  cursor: pointer;
}

a {
  text-decoration: none;
}

h2 {
  margin-bottom: 1rem;
}

#main-heading {
  margin-bottom: 3rem;
}

.logo {
  max-height: 6rem;
  margin: auto;
  margin-bottom: 0.75rem;
}

.main-text {
  margin: auto;
  margin-bottom: 2rem;
}

.tabs {
  padding-bottom: 1rem;
  max-width: 700px;
  margin: auto;
  border-bottom: 1px solid #7b6152;
  display: flex;
  flex-wrap: wrap;
  
  justify-content: center;
}

.tabs button{
  margin: 1rem 2rem;
}

.tabs button {
  background: #d5bdaf;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
}

.tabs button.active {
  background: #edede9;
  cursor: inherit;
}

.tabs button:hover:not(.active) {
  background: #e3d5ca;
}

.content ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 700px;
  margin: 1rem auto;
  padding: 0;
}

.content button {
  transition: background 0.3s;
}

/* ---------INPUT--------------- */

.search-wrapper {
  position: relative;
  display: inline-block;
  padding: 0;
  text-align: center;
  justify-items: center;

  
}

.search-wrapper button{
  color: #fff !important;
}

.search-results section {
  text-align: center;
}

input,
textarea {
  border: 2px solid #d5bdaf;
  padding: 0.5rem 1rem;
  border-radius: 16px;
  box-shadow: 2px 2px 5px #d6ccc2;
  margin: 0.5rem;
  width: 400px;
  transition: background 0.3s, border-color 0.3s;
}

.search-wrapper input[type="text"] {
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  min-width: min(50vw, 400px);
  width: auto;
}

.search-wrapper input[type="text"]:focus {
  border-color: #e3d5ca;
  outline: none;
}

.search-wrapper input[type="text"]::placeholder {
  color: #a09b94;
}

.search-wrapper button {
  position: absolute;
  top: 1rem;
  right: 0px;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 0;
  height: 3rem;
  width: 3rem;
  border: none;
  background: #7b6152;
  color: #edede9;
  border-radius: 50%;

  
}

.search-wrapper button i {
  color: #edede9;
}

.search-wrapper button:hover {
  color: #d5bdaf;
}

/* login */

.login {
  text-align: center;
  justify-content: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.login input {
  max-width: 300px;
}

.login h2 {
  margin-bottom: 2rem;
}

/* -------ADMIN----------- */

#admin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 3rem;
  text-align: center;
}

.bottom-buttons {
  border-top: 2px solid #7b6152;
  padding-top: 3rem;
  margin-top: 3rem;
}

#admin h2 {
  margin-bottom: 2rem;
}

/* -------ADD BOOK----------- */

.add-book {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  
}


.add-book h2 {
  text-align: center;
  margin-bottom: 2rem;
}

.add-book button {
  align-self: center;
}


.form-field {
  display: flex;
  flex-direction: column;
}

/* .price input {
  width: 100px;
} */
.form-field textarea {
  height: 250px;
}

/* delete book */

.bookCoverDelete {
  max-height: 150px;
  max-width: 300px;
  object-fit: contain;
}

.delete,
.edit {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.delete button,
.edit button {
  margin-bottom: 2rem;
}


.delete p,
.edit p {
  margin: 1rem;
}


.delete .bookContainer,
.edit .bookContainer {
  border: 1px solid #7b6152;
  padding: 1rem;
  margin: 1rem;
}


.delete .form ,
.edit .form {
  margin: auto;
}

.outContainer {
  padding: 3rem;
}

button {
  color: #000 !important;
}
/* --------- MOBILE----------- */

@media screen and (max-width: 756px) {
  .bookList {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;

    
  }

  .tabs button{
    margin: 1rem 2rem;
  }

  .bookList .bookCard {
    width: auto;
    margin: 1rem 0;
  }

  form {
    max-width: 100vw;
  }

  input,
  textarea {
    width: 100%;
    box-sizing: border-box;
  }

  .App {
    padding: 1rem;
  }

  .outContainer {
    padding: 1rem;
  }
  .delete {
    padding: 1rem;
    max-width: 100vw;
  }

  .delete div {
    max-width: 100%;
  }

  .delete input {
    width: auto;
  }

  .delete img {
    max-width: 100%;
    object-fit: contain;
  }

  .delete .results {
    width: 100%;
  }
  .delete .bookContainer {
    width: auto;
  }


  .bookCard {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bookCard img {
    width: min(300px, 80vw);
    object-fit: contain;
  }
}

@media screen and (min-width: 411px) {
  .bookList {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
}

/* Books */

.bookCard:hover {
  strong {
    text-decoration: underline;
  }
}
.bookCard {
  margin: 2rem;
  
}


.bookCard  strong,
.bookCard span {
    color: #000;
  }

  .bookCard img {
    max-width: 150px;
    height: 200px;
    object-fit: contain;
  }
  .bookCard .text {
    display: flex;
    flex-direction: column;
    max-width: 150px;
    text-align: start;
  }

  .bookPage p{
    margin-bottom: 1rem;
    text-align: justify;
  }

  .bookPage .importantText{
    font-size: large;
  }

.random-book {
  text-align: center;
}

.random-book p{
  margin-bottom: 0.5rem;
}

.random-book h3{
  margin-bottom: 1rem;
}
.bookPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.bookCoverMain {
  max-height: 300px;
}

.bookCard {
  margin: 2rem;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.bookList {
  max-width: 900px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  margin: auto;
  justify-content: center;
}

.pagination {
  justify-content: center;
  display: flex;
  padding-left: 0;
  list-style: none;
  margin: 0;
}

.page-item .page-link {
  position: relative;
  display: block;
  margin: 0 2px;
  min-height: 40px;
  min-width: 40px;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  color: #7b6152;
  text-decoration: none;
}

.page-item a.page-link:hover {
  background-color: #cccccc;
}

.page-item.active .page-link {
  font-weight: 700;
  color: #ffffff;
  background-color: #7b6152;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
}

.outContainer {
  max-width: 900px;
  margin: auto;
}

.additional-image {
  max-width: min(700px, 90vw);
  display: block;
  margin: 1rem;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  position: relative;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-image {
  /* width: 100%; */
  height: auto;
  max-height: 80vh;
  max-width: 100%;
}

.bookCoverMain,
.additional-image {
  cursor: pointer;
  max-width: 200px;
  max-height: 300px;
}

.recently-text {
  color: #000;
}


.related-books-section {
  margin-top: 20px;
}

.related-books {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  padding: 10px 0;
}

.related-book-item {
  flex-shrink: 0;
  width: 120px;
  text-align: center;
}

.related-book-item img {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.related-book-item p {
  margin-top: 5px;
  font-size: 0.9em;
  color: #333;
}

.related-books-section h3 {
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #444;
}
